<template>
    <div class="view pa24">
        <el-button class="mr10" type="primary" @click="openAdd()"
        >添加打印任务
        </el-button
        >
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="pageNum"
                :total="total"
        >
            <template v-slot:table>
                <!--<el-table-column align="center" prop="taskId"  label="序号"/>-->
                <el-table-column
                        prop="lotNumber"
                        align="center"
                        label="批次号"

                />
              <el-table-column prop="packBatch"  align="center" label="类型" >
                <template slot-scope="scope">
                  {{(scope.row.type==3?'箱码': '产品码')}}

                </template>
              </el-table-column>
                <el-table-column prop="packBatch"  align="center" label="批次号" >
                    <template slot-scope="scope">
                        {{(scope.row.batchNumber|| '---')}}
                        <span>(数量:{{scope.row.num||0}})</span>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="boxBatch"  align="center" label="箱标批次号">-->
<!--                    <template slot-scope="scope">-->
<!--                        {{scope.row.type == 3 ? (scope.row.batchNumber|| '-&#45;&#45;') : '-&#45;&#45;'}}-->
<!--                        <span v-if="scope.row.type == 3">(数量:{{scope.row.num||0}})</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->

                <el-table-column prop="liablePerson" align="center" label="操作员"/>
<!--                <el-table-column prop="endTime" align="center" label="结束时间"/>-->
                <el-table-column prop="createTime" align="center" width="155px" label="创建时间"/>
<!--                <el-table-column align="center"  prop="state" label="状态">-->
<!--                    <template slot-scope="scope">-->
<!--                        {{ scope.row.status|taskStatusFilter(scope.row.postStatus) }}-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="edit(scope.row, true);">编辑
                        </el-button>
                        <customPopconfirm confirm-button-text="确定" k cancel-button-text="取消"
                                          @confirm="del(scope.row.taskId)" icon="el-icon-info" icon-color="red"
                                          title="确定要删除吗？">
                            <el-button slot="reference" class="ml10" style="color: #fd634e" type="text">删除
                            </el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <!--添加、编辑任务弹窗-->
        <el-dialog :title="diagTitle" :visible.sync="centerDialogVisible" @open="openDialog" @close="close"
                   :close-on-click-modal="false" :modal-append-to-body="false" :destroy-on-close="true" width="50%"
                   center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">

                <el-form-item label="类型" prop="type">
                    <el-select v-model="ruleForm.type" placeholder="请选择">
                        <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-if="ruleForm.type == 2">
                    <el-form-item label="产品批次号" prop="packBatchId">
                        <el-select v-model="ruleForm.packBatchId" placeholder="请选择" style="width: 80%">
                            <el-option
                                    v-for="item in proOption"
                                    :key="item.lotNumber"
                                    :label="item.lotNumber + '---' + ''+item.count+'个'"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>
                <template v-else-if="ruleForm.type == 3">
                    <el-form-item label="箱标批次号" prop="boxBatchId">
                        <el-select v-model="ruleForm.boxBatchId" placeholder="请选择" style="width: 80%">
                            <el-option
                                    v-for="item in xbOption"
                                    :key="item.lotNumber"
                                    :label="item.lotNumber + '---' + '     '+  item.count+'个'"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>
            </el-form>
            <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</span>
        </el-dialog>

    </div>

</template>

<script>

    import commonTable from "@/components/common/commonTable";
    import {mapState} from 'vuex';
    import customPopconfirm from "@/components/common/customPopconfirm";
    import {
      selectJob,addJob,getLotNumber,getBatch
    } from "@/api/printJob";

    let ac;
    export default {
        name: 'printJob',
        data() {
            return {
                pageData:{},
                taskHandleData: [],
                taskInfoDialogVisible: false,
                taskDownVisible: false,
                TipsInfo: false,
                isPostDialogVisible: true,
                diagTitle: '添加打印任务',
                proValue: '',
                proOption: [],
                pbOption: [],
                xbOption: [],
                deviceList:[],
                //
                tableData: [],
                pageNum: 1, // 当前页
                pageSize: 10, //显示条数
                loading: false, //表格加载
                total: 0, //总条数
                taskList: [],
                centerDialogVisible: false,
                bEdit: false,
                editUpdate: true,
                isUpdate: true,
                fileList: [],
                userInfo: this.$store.state.loginRoot.userInfo,
                pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }],
                    disabledDate: time => {
                        let endDateVal = this.ruleForm.endTime;
                        if (endDateVal) {
                            return time.getTime() > new Date(endDateVal).getTime();
                        }
                    }
                },
                pickerOptionsEnd: {
                    disabledDate: time => {
                        let beginDateVal = this.ruleForm.startTime;
                        if (beginDateVal) {
                            return (
                                time.getTime() <
                                new Date(beginDateVal).getTime()
                            );
                        }
                    }
                },
                ruleForm: {
                  id: '',
                  type: '',
                  boxBatchId: '',
                  packBatchId:'',
                //     taskName: '', //任务名称
                //     productBatch: '', // 产品批次号
                //     packBatch: '', // 盒标批次号
                //     boxBatch: '', // 箱标批次号
                //     leader: '', // 责任人
                //     num: '', // 产品总数
                //     boxNum: '', // 箱子总数
                //     packNum: '', //包装总数
                //     startTime: '',
                //     endTime: '',
                //     ratio: '',
                //     packRatio: '',
                //     boxRatio: 1,
                //   sacnDeviceId:''
                },
                rules: {
                    type: [
                        {required: true, message: '请选择类型', trigger: ['blur', 'change']}
                    ],
                    // taskName: [{required: true, message: "请输入任务名称", trigger: "blur"}],
                    packBatchId: [{required: true, message: "请选择产品批次号", trigger: "change"}],
                    boxBatchId: [{required: true, message: "请选择箱标批次号", trigger: "change"}],
                    // sacnDeviceId: [{required: true, message: "请选择扫码枪", trigger: "change"}],
                    // desc: [
                    //     {required: true, message: '请填写下发信息', trigger: 'blur'}
                    // ],
                    // deviceId: [
                    //     {required: true, message: '请选择设备', trigger: ['blur', 'change']}
                    // ],
                },
                typeOptions: [
                    {
                      label: '产品标',
                      value: 2
                    },
                    {
                      label: '箱标',
                      value: 3
                    },
                ],
                devOption: [],
                downForm: {
                    taskId: '',
                    deviceId: '', // 设备id
                    postNum: '',
                    postStatus: '',
                    remarks: '',
                },
                downRules: {}
            };
        },
        components: {
            commonTable,
            customPopconfirm,
        },
        filters: {
            taskStatusFilter(status,postStatus) {
                if (status != null) {
                    if (status == 0) {
                        if (postStatus){
                            return'已下发'
                        } else {
                            return '待开始'
                        }
                    } else if (status == 1) {
                        return '进行中'
                    } else if (status == 2) {
                        return '已完成'
                    } else if (status == 3) {
                        return '过期'
                    }
                }
            }
        },
        computed: {
            ...mapState(['routerArr']),
        },
        watch: {},
        created() {
        },
        mounted() {
            this.queryPage();
            this.getProductBatch();
            // this.getPackBatch();
            this.getBoxBatch();
        },
        beforeDestroy() {

        },
        methods: {
          getLotNumber(){
            getLotNumber().then(res=>{
              this.ruleForm.lotNumber = res.data
            })
          },
            noVisible() {
                this.TipsInfo = false;
                this.isPostDialogVisible = true;
                this.taskDownVisible = false;
                this.downForm = {
                    taskId: '',
                    deviceId: '', // 设备id
                    remarks: '',
                }
            }
            ,
            isVisible() {
                if (this.isPostDialogVisible) {
                    this.isPostDialogVisible = false
                } else {
                    if (!this.downForm.remarks) {
                        return this.$message({
                            message: '请填写下发信息！',
                            type: 'warning'
                        });
                    }
                    this.TipsInfo = false;
                    this.isPostDialogVisible = false;
                    this.taskDownVisible = false
                    //请求任务下发接口
                    console.log(this.downForm)
                    this.distribData();
                }
            },
            async queryPage() {
                let data = {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                };

                try {
                    this.loading = true;
                    const result = await
                        selectJob(data);
                    this.loading = false;
                    const {
                        total,
                        list
                    } = result.data;
                    this.tableData = list;
                    this.total = total;
                } catch (error) {
                    this.loading = false;
                }
            }
            ,
            async getProductBatch() {
                const result = await
                    getBatch({
                        type: 2
                    })
                this.proOption = result.data
            }
            ,
            async getBoxBatch() {
                const result = await
                    getBatch({
                        type: 3
                    })
                console.log('箱标批次', result.data);
                this.xbOption = result.data
            }
            ,
            // async getPackBatch() {
            //   const result = await
            //       getTaskNums({
            //         type: 5
            //       })
            //   this.pbOption = result.data
            // }

            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.ruleForm.coverImg = "";
            }
            ,

            /**@method 模态框打开时的回调 */
            openDialog() {

            }
            ,
            /**@method 模态框关闭的回调 */
            close() {
                (this.ruleForm = {
                    lotNumber: '', //任务名称
                    packBatchId: '', // 产品批次号
                    boxBatchId: '', // 箱标批次号
                }),
                    (this.fileList = []);
                this.bEdit = false;
                this.diagTitle = '添加任务'
            }
            ,
            setValue(e) {
                const {
                    item: {value},
                } = e;
                this.ruleForm.address = `${value.city}${value.district}${value.business}`;
            }
            ,
            /**@method 编辑
             * @param {Object} val - 当前点击行的值
             */
            edit(val, b) {
                let data = JSON.parse(JSON.stringify(val));
                // this.getDevice()
                this.centerDialogVisible = true;
                // 回显数据
                if(data.type == 2) {
                    this.ruleForm.id = data.id,
                    this.ruleForm.type = parseInt(data.type)
                    this.ruleForm.packBatchId = data.batchId
                }else if (data.type == 3) {
                    this.ruleForm.id = data.id,
                    this.ruleForm.type = parseInt(data.type)
                    this.ruleForm.boxBatchId = data.batchId
                }
                this.diagTitle = '编辑箱盒任务'
                this.editUpdate = true
            }
            ,
            /**@method 添加任务 */
            determine() {
                this.$refs.ruleForm.validate(async (valid) => {
                        if (valid) {
                            try {
                                let params = {}
                                if(this.ruleForm.type == 2) {
                                    params = {
                                        type: this.ruleForm.type,
                                        batchId:this.ruleForm.packBatchId,
                                    }
                                }else if (this.ruleForm.type == 3) {
                                    params = {
                                        type: this.ruleForm.type,
                                        batchId: this.ruleForm.boxBatchId,
                                    }
                                }
                                this.$message({
                                    message: "正在添加",
                                });
                                if (this.editUpdate) {
                                    params.id= this.ruleForm.id
                                    console.log('编辑参数',params)
                                    // await addJob(params);
                                    // this.$message({
                                    //     message: "编辑成功",
                                    //     type:"success"
                                    // });
                                    // this.queryPage();
                                    // this.getBoxBatch();
                                    // this.getProductBatch();
                                } else {
                                    await addJob(params);
                                    this.$message({
                                        message: "添加成功",
                                        type:"success"

                                    });
                                    this.queryPage();
                                    // this.getBoxBatch();
                                    // this.getProductBatch();
                                }
                                this.centerDialogVisible = false;

                            } catch (error) {
                                this.$message({
                                    message: error.message,
                                    type: "error",
                                });
                            }
                        }
                        else {
                            console.log("error submit!!");
                            return false;
                        }
                    }
                );
            }
            ,
            /**@method 删除
             * @param {String} val - 文章id
             */
            async del(val) {
                try {
                    await deleteById({taskId: val});
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    await this.queryPage();
                    // await this.getPackBatch();
                    // await this.getBoxBatch();
                    // await this.getProductBatch();
                } catch (error) {
                    console.log(error);
                }
            },
            handleSizeChange(num) {
                this.pageSize = num
                this.pageNum = 1
                this.queryPage()
            },
            handleCurrentChange(num) {
                this.pageNum = num
                this.queryPage()
            },
            ratioChange(val) {
                this.ruleForm.packRatio = val
            },
          // async getDevice(){
          //     const param = {type:3}
          //   getNopageDevice(param).then(response=>{
          //     this.deviceList = response.data
          //   })
          // },
          openAdd(){
            this.centerDialogVisible=true;
            this.editUpdate = false;
            this.getLotNumber()
            // this.getDevice()
          }
        },
    }
    ;
</script>

<style lang="scss" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 180px;
        text-align: center;
    }

    /deep/ .avatar-coverImg .el-upload--text {
        width: 100px;
        height: 100px;
    }

    /deep/ .avatar-coverImg .avatar-uploader-icon {
        line-height: 100px;
    }

    .select-goods-list {
        margin-bottom: 20px;
        border-radius: 40px;
        box-sizing: border-box;
        padding: 0 20px;
        background: #ffffff;
        transition: all 0.4s;
    }

    .select-goods-list:hover {
        background: #f5f5f6;
        transition: all 0.4s;
        cursor: pointer;
    }


    /deep/ .disabled .el-upload--picture-card {
        display: none;
    }

    .info {
        cursor: pointer;
        color: #113DEE;
    }

/deep/ .disabled .el-upload--picture-card {
display: none;
}
.info{
cursor: pointer;
color: blue;
}

/deep/ .disabled .el-upload--picture-card {
display: none;
}


/deep/ .details .el-dialog__body {
text-align: center;
font-size: 16px
}

/deep/ .details .el-dialog__body div {
line-height: 32px;
}

/deep/ .details .el-dialog__body div span:first-child {
width: 180px;
display: inline-block;
text-align: right;
}

/deep/ .details .el-dialog__body div span:last-child {
width: 180px;
display: inline-block;
text-align: left;
}
.tip{
    color: #f00;
    padding-left: 20px;
}
</style>
